/* ------------------------------------------------------------ *\
	collaborator
\* ------------------------------------------------------------ */

.collaborator {
  &s ul {
    list-style: none outside none;
    padding: 0 10px;
  }
  &s ul li {
    padding: 11px 0;
  }
  &s ul a {
    font-size: 1rem;
    display: block;
    color: #cfd2da;
  }
  &s ul a:hover {
    text-decoration: none;
  }
  &s ul a img {
    margin-right: 8px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
  }
}
