
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-bold-webfont.eot');
    src: url('../fonts/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-bold-webfont.woff') format('woff'),
         url('../fonts/opensans-bold-webfont.ttf') format('truetype'),
         url('../fonts/opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: 700;
    font-style: normal;
}


@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-light-webfont.eot');
    src: url('../fonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-light-webfont.woff') format('woff'),
         url('../fonts/opensans-light-webfont.ttf') format('truetype'),
         url('../fonts/opensans-light-webfont.svg#OpenSansLight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-regular-webfont.eot');
    src: url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/opensans-regular-webfont.ttf') format('truetype'),
         url('../fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: 400;
    font-style: normal;
}


@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway_400_normal_1427270369.eot");
  src: url("../fonts/Raleway_400_normal_1427270369.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Raleway_400_normal_1427270369.svg#Raleway") format("svg"),
    url("../fonts/Raleway_400_normal_1427270369.woff") format("woff"),
    url("../fonts/Raleway_400_normal_1427270369.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway_900_normal_1427270369.eot");
  src: url("../fonts/Raleway_900_normal_1427270369.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Raleway_900_normal_1427270369.svg#Raleway") format("svg"),
    url("../fonts/Raleway_900_normal_1427270369.woff") format("woff"),
    url("../fonts/Raleway_900_normal_1427270369.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway_300_normal_1427270369.eot");
  src: url("../fonts/Raleway_300_normal_1427270369.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Raleway_300_normal_1427270369.svg#Raleway") format("svg"),
    url("../fonts/Raleway_300_normal_1427270369.woff") format("woff"),
    url("../fonts/Raleway_300_normal_1427270369.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway_600_normal_1427270369.eot");
  src: url("../fonts/Raleway_600_normal_1427270369.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Raleway_600_normal_1427270369.svg#Raleway") format("svg"),
    url("../fonts/Raleway_600_normal_1427270369.woff") format("woff"),
    url("../fonts/Raleway_600_normal_1427270369.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway_700_normal_1427270369.eot");
  src: url("../fonts/Raleway_700_normal_1427270369.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Raleway_700_normal_1427270369.svg#Raleway") format("svg"),
    url("../fonts/Raleway_700_normal_1427270369.woff") format("woff"),
    url("../fonts/Raleway_700_normal_1427270369.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}