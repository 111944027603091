/* ------------------------------------------------------------ *\
	workspace
\* ------------------------------------------------------------ */

.workspace {
	height: 100vh;
	position: relative;
	padding-top: 49px;

	&-logo {
		position: absolute;
		left: 15px;
		bottom: 15px;
		width: 108px;
		height: 11px;
	}

	&-content {
		margin: 0 400px 0 0;
		padding: 15px 0;
		height: 100%;
		overflow-y: auto;
		overflow-scrolling: touch;
	}

	&-sidebar {
		position: absolute;
		top: 49px;
		bottom: 0;
		right: 0;
		width: 400px;
		background: #2e323c;
		padding-top: 45px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.01s linear;
        -o-transition: all 0.1s linear;
    }
    
	&-sidebar-handle {
		position: absolute;
		left: -1px;
		top: 0;
		bottom: 0;
		width: 2px;
		cursor: ew-resize;
		padding: 0 2px;
		z-index: 10;
		background: #222;
    }
    
	&-sidebar-head {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		height: 45px;
    
        & .nav-tabs {
            color: $gray;
            font-weight: 400;
            padding-top: 10px;
            border-bottom-color: transparent;

            li {
                width: 33%;
            }
        
            .nav-link {
                border: 0 none;
                display: block;
                text-align: center;
                cursor: pointer;
                transition: color 300ms ease-in-out;

                &:hover {
                    color: #fff;
                }

                &.active {
                    border-bottom: 2px solid $app-yellow;
                    color: #fff;
                    transition: border 200ms ease;
                }
            }
        }
    }

	&-sidebar-resizing &-sidebar-handle,
	&-sidebar-handle:hover {
		//   &-bottombar-resizing &-bottombar-handle, &-bottombar-handle:hover {
		background: #4d5262;
		width: 3px;
	}

	&-sidebar-body {
		height: 100%;
		position: relative;
		overflow: hidden;
		padding-top: 10px;
    }
    
	&-sidebar-resizing,
	&-bottombar-resizing {
		pointer-events: none;
	}

	&-tabs-content {
		height: 100%;
	}
	&-tabs-content .tab-pane {
		height: 100%;
		position: relative;
		overflow-y: auto;
	}
	&-tabs-content .tab-pane-no-overflow {
		overflow-y: hidden;
	}

	@media (max-width: 767px) {
		padding-top: 90px;

		&-logo {
			display: none;
		}
		&-content {
			height: auto;
			margin-right: 0 !important;
			overflow: visible;
		}
		&-sidebar {
			position: static;
			width: auto !important;
			padding-top: 0;
		}
		&-sidebar-head {
			position: static;
		}
		&-sidebar-body {
			height: auto;
			overflow: visible;
		}
	}

	&-mediaContainer {
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.01s linear;
        -o-transition: all 0.1s linear;
    }
    
    .bottombar-title {

		color: $gray;
        font-size: 0.7rem;
        font-weight: 600;
        letter-spacing: .03rem;
        margin: 0;
        text-align: left;
        text-transform: uppercase;

        &.right {
			text-align: right !important;
			float: right;
        }

        & .nav-tabs {
            border-bottom-color: transparent;
            display: flex;
            flex-direction: row;

            li {
                border: 0 none;
                display: block;
                cursor: pointer;
                margin-left: 1.5rem;
                text-align: left;
                transition: color 300ms ease-in-out;
            }

            li:first-of-type {
                margin-left: 0 !important; 
            }

            & .nav-link {
                border: 0 none;
                border-bottom: 2px solid transparent;
                display: block;
                text-align: center;
                cursor: pointer;
                padding: 0;
                padding-bottom: 3px;
                transition: color 300ms ease-in-out;
    
                &:hover {
                    color: $gray-light;
                }
            
                &.active {
                    border-bottom: 2px solid $app-yellow;
                    color: $gray-light;
                    transition: border 200ms ease;
                }
            }
        }

        &.checkbox, .checkbox {
			color: $gray;
            &:hover {
                cursor: pointer;
            }
            &:focus {
                outline: 0;
            }
        }

        & i {
            margin-left: 8px;
            font-size: .95rem;
            vertical-align: -.0667em;
		}
		
		& button {
			background: transparent;
		}
	}

	&-bottombar {
		position: absolute;
		bottom: 0;
		width: 100%;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.01s linear;
        -o-transition: all 0.1s linear;

		&-handle {
            margin-top: 5px;
            margin-bottom: 5px;
			height: 3px;
			width: 100%;
			cursor: ns-resize;
			z-index: 10;
			background: #222;

			&:hover,
			&-resizing {
				background: #4d5262;
				height: 3px;
			}
        }
	}
}

// for the tab navigation on the dashboards
#bottombar-tabs {
    padding: 0;
    margin-top: 10px;
    color: $gray;
    font-size: 1.1rem;
    background-color: transparent;
    
    .list-group {     
        &-item {
            padding: 0.5rem;
			height: 40px;
            width: 40px;
            cursor: pointer;
			justify-content: center;
            border: 0;
            border-radius: 0;
            transition: color 300ms ease-in-out;

			&:hover {
                color: rgba($gray, 0.5);
			}
		}
	}
	.active {
        background: transparent;
        color: #fff;
        border-bottom: 2px solid #ffc005 !important;
        transition: border 200ms ease;
        &:hover {
            color: #fff;
        }
	}
}

@media (max-width: 991px) {
	#bottombar-tabs {
		ul {
			flex-direction: row;
			.list-group-item {
				margin-bottom: 0;
				margin-right: -1px;
				&:first-child {
					//border-radius: 0;
				}
				&:last-child {
					//border-radius: 0 0.25rem 0.25rem 0;
				}
			}
		}
	}
}

#timeline-wrapper {
	margin-top: 5px;
}

.dashboard-collapser {
	// appear is used when component is first mounted. We only do a fade of opacity
	&-appear {
		opacity: 0.01;
	}
	&-appear.dashboard-collapser-appear-active {
		opacity: 1;
		@include __transitions(500ms);
	}
	// enter / exit are used when the component is rendered or removed from the page
	// 220px because that's the current size of the dashboard + comment area.
	&-enter {
		opacity: 0.01;
		height: 0;
		overflow: hidden;
		//border-bottom: 1px solid $app-yellow;
	}
	&-enter.dashboard-collapser-enter-active {
		height: 240px;
		opacity: 1;
		@include __transitions(700ms);
	}
	&-exit {
		height: 240px;
		opacity: 1;
		//border-bottom: 1px solid $app-yellow;
	}
	&-exit.dashboard-collapser-exit-active {
		opacity: 0.01;
		height: 0;
		overflow: hidden;
		@include __transitions(700ms);
	}
}
