// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
	$active-background: darken($background, 10%);
	$active-border: darken($border, 12%);

	color: $color;
	background-color: $background;
	border-color: $border;
	@include box-shadow($btn-box-shadow);

	// Hover and focus styles are shared
	@include hover {
		color: $color;
		background-color: $active-background;
		border-color: $active-border;
	}
	&:focus,
	&.focus {
		// Avoid using mixin so we can pass custom focus shadow properly
		@if $enable-shadows {
			box-shadow: $btn-box-shadow, 0 0 0 2px rgba($border, 0.5);
		} @else {
			box-shadow: 0 0 0 2px rgba($border, 0.5);
		}
	}

	// Disabled comes first so active can properly restyle
	&.disabled,
	&:disabled {
		background-color: $background;
		border-color: $border;
	}

	&:active,
	&.active,
	.show > &.dropdown-toggle {
		color: $color;
		background-color: $active-background;
		background-image: none; // Remove the gradient for the pressed/active state
		border-color: $active-border;
		@include box-shadow($btn-active-box-shadow);
	}
}

@mixin button-outline-variant($color, $percentage) {
	color: $color;
	background-image: none;
	background-color: transparent;
	border-color: darken($color, $percentage);

	@include hover {
		border-color: $color;
		color: $color;
	}

	&:focus,
	&.focus {
		box-shadow: none;
	}

	&.disabled,
	&:disabled {
		color: $color;
	}

	&:active,
	&.active,
	.show > &.dropdown-toggle {
		border-color: $color;
	}
}

@mixin button-transparent($color) {
	$background: transparent;
	$border: none;
	$active-background: transparent;
	$active-border: transparent;

	color: $color;
	background-color: $background;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	padding-bottom: 0.5rem;

	// Hover and focus styles are shared
	@include hover {
		color: lighten($color, 15%);
		border-color: none;
	}

	// Disabled comes first so active can properly restyle
	&.disabled,
	&:disabled {
		background-color: $background;
	}

	&:active,
	&.active,
	.show > &.dropdown-toggle {
		color: $color;
		background-color: $active-background;
		background-image: none; // Remove the gradient for the pressed/active state
	}
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $border-radius) {
	padding: $padding-y $padding-x;
	font-size: $font-size;
	@include border-radius($border-radius);
}
