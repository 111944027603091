/* ------------------------------------------------------------ *\
	Main
\* ------------------------------------------------------------ */

.main {
  &-access {
    background: $brand-white;
    color: $primary-color;
    padding: 60px 60px 36px;
    border-radius: 4px;
  }

  @media (max-width: 767px) {
    &-access {
      padding: 20px;
    }
  }
}
