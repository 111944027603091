/* ------------------------------------------------------------ *\
	replyblock
\* ------------------------------------------------------------ */

.replyblock {
  margin: 5px 0px 0px 0px;
  padding-top: 10px;
  padding-left: 3px;
  padding-bottom: 5px;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid transparent;
  transition: all .2s ease-in-out;

  &:hover {
    border-bottom: 1px solid darken(#2e323c, 5%) !important;
  }

  &:first-child:before {
    left: -35px;
  }

  &-info {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &-name {
    margin-left: 8px;
    font-size: .75rem;
    font-weight: 600;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &-date {
    margin-left: 10px;
    color: $text-muted;
    font-weight: 400;
    font-size: .8rem;
    flex: 1;
  }

  &-text {
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 4px;
    margin-right: 20px;
    padding-left: 30px;
    font-size: 13px;
    display: inline-block;
  }
  &-actions {
    position: relative;
  }

  &-delete {
    position: absolute;
    right: 25px;
    display: none; //inline-block;
  }
  &:hover &-delete {
    display: inline-block;
  }

  // &-reply {
  //   font-size: 12px;
  //   color: $text-muted;    
  // }
  // &-reply:focus, &-reply:hover {
  //   text-decoration: none;
  //   color: $text-muted;
  // }

  .form-control {
    margin-left: 27px;
    padding: 3px;
    width: 85%;
    display: none;
    font-size: 13px;
  }

  &-editing .form-control {
    display: inline-block;
  }
  &-editing &-text {
    display: none;
  }
}
