/* ------------------------------------------------------------ *\
	uploader
\* ------------------------------------------------------------ */

.uploader {
  &-thumb {
    border: 2px dashed #6f7890;
    // color: #6f7890;
    height: 226px;
    border-radius: .5rem;
    position: relative;
    width: 235px;
	margin: 0 auto 30px;

	-webkit-transition: border .5s ease-out;
  	-moz-transition: border .5s ease-out;
  	-o-transition: border .5s ease-out;
  	transition: border .5s ease-out;

	& .uploadIcon {
		color: $app-yellow;
	}

	&:hover {
		border: 2px dashed #c1c6d4;
	}
  }

  &-thumb-dragover {
    border-color: $brand-primary;
    color: $brand-primary;
  }

  &-thumb-face {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
 
	& span {
		display: block;
		font-size: 1rem;
		font-weight: 400;
		margin-top: 14px;

		&:hover {
			text-decoration: underline;

		}
	}
  }

  &-text-brand-info {
    color: $brand-info;
  }
  &-text-brand-success {
    color: $brand-success;
  }
  &-text-brand-primary {
    color: $brand-primary;
  }
  &-text-brand-warning {
    color: $app-yellow;
  }
}
