/* ------------------------------------------------------------ *\
	Access
\* ------------------------------------------------------------ */

.access {
	text-align: center;

	&-logo {
		background: url(../images/access-logo.png) no-repeat 0 0;
		width: 100px;
		height: 100px;
		background-size: 100% 100%;
		display: block;
		margin: 0 auto .5rem;
		font-size: 0;
		line-height: 0;
		text-indent: -4000px;
	}
	h1, h2, small {
        font-family: 'Raleway';
		letter-spacing: 3px;
		font-weight: 500;
        color: $primary-color;
    }
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.3rem;
        margin-top: 10px;
    }
	small {
        margin-top: 10px;
	}
}
