/* ------------------------------------------------------------ *\
	form
\* ------------------------------------------------------------ */
#generalModals {
    display: block;
    
	& .modal-header {
		background-color: $gray-lightest;
		border-top-left-radius: .3rem;
		border-top-right-radius: .3rem;
        border-bottom-color: darken($gray-lightest, 4%);
        display: flex;
        flex-direction: column;
        align-items: left !important;
    }
    
    & .modal-title {
        font-size: 1.2rem;
        margin-top: .1rem;
    }
    
	& .modal-subtitle {
        font-size: .75rem;
        font-weight: 600;
        text-transform: uppercase;
    }

	& .modal-body {
		font-size: .9rem;
        margin-bottom: 1em;
        padding-top: 1em;
		padding-bottom: 1em;

        // overwrites default of the below margins reversed. 
        // user where confirmation message exceeds 1 paragraph.
        p {
            margin-top: .9rem;
            margin-bottom: 0rem;
        }

		span {
			margin: 0;
        }
        
		& .hidden-content {
			display: none;
		}

		input[type=text] {
			color: $gray;
			border: none;
			border-bottom: 1px solid #eee;
			transition: all .2s ease;

			&:hover {
				color: $gray-dark;
				border-bottom-color: $teal !important;
			}
			&:focus {
				color: $blue;
				outline: none;
				border-bottom-color: $blue !important;
			}
		}

		input[type=file] {
			color: transparent;

			&::-webkit-file-upload-button {
				visibility: hidden;
			}

			&::before {
				background-color: #1997c6;
				border-color: #1997c6;
				cursor: pointer;
			}

			&:disabled::before, &::before {
				content: "Select a file";
				display: inline-block;
				font-weight: 400;
				line-height: 1.25;
				text-align: center;
				white-space: nowrap;
				user-select: none;
				border: 1px solid transparent;
				padding: .1rem 1rem;
				font-size: .9rem;
				border-radius: .25rem;
				transition: all .2s ease-in-out;
				color: #fff;
				margin-bottom: .8rem;
			}

			&:disabled::before {
				cursor: not-allowed;
				font-style: italic;
				font-weight: 300;
				margin-top: 1rem;
			}

			&:hover::before, &:active::before {
				color: #fff;
  				background-color: #137499;
  				border-color: #126e90;
			}

			&:active, &:focus {
				outline: 0;
  				box-shadow: 0 0 0 0 transparent;
			}
		}

		label {
			margin-right: 1em;
			padding-top: .05em;
			white-space: nowrap;
		}

		

		& .row {
            justify-content: center;
            
			& .input-group {   
                padding: .4em 0.6em 0em 0.6em;

                textarea {
                    resize: none;
                    overflow: hidden;
                    width: 87.5%;
                    &::placeholder {
                        color: $gray-light;
                        font-style: italic;
                    }
                    
                    &:focus {
                        border-color:$brand_primary;
                    }
                }

                &-btn {
                    margin: 0;
                }
            }

			& .fa-check-square, .fa-square-o {
				color: $brand-success;
			}
        }
        
        & .data-list-container {
            padding: .4em 2em 0em 2.3em;

            ul {
                li.list-group-item {
                    padding: .8rem .25rem .3rem .25rem;
                    justify-content: space-between;
                    border: none;
                    border-bottom: 1px solid #eee;
                    flex-flow: nowrap;
					cursor: pointer; 

					&:last-child {
						margin-bottom: 1.4em;
					}
			
                    &:hover {
                        color: $gray-dark;
                        border-bottom-color: $teal !important;
                    }

                    &:focus {
                        color: $brand_primary;
                        outline: none;
                        border-bottom-color: $brand_primary !important;
					}
					
					& .fill-available {
						width: -moz-available;
  						width: -webkit-fill-available;
  						width: fill-available;
					}
				}
				
				input[type=text] {
					width: 84.5%;
				}

				i.delete {
					padding: .25em; // To make a larger click/tap target
					font-size: 120%;
					color: lighten($gray, 20%);
					cursor: pointer;
					transition: color .2s ease;

					&:hover,
					&:focus {
						color: $blue;
					}
					&:active {
						color: $teal;
					}
				}				

            }
            
            & .list-over-flow {
                overflow-y: auto;
                max-height: 50vh;
            }
        }

		& .modal-error {
			color: $brand-danger;
			font-weight: 400;
		}

		& .modal-saving {
			color: $brand-success;
			font-weight: 400;
		}

		&-changePlan {
			p {
				text-align: justify;
			}

			b {
				text-transform: capitalize;
			}
		}
		
		&-dashboard-tags {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
        }
        
		h6#help-title {
			display: inline-block;
			margin-top: 1em;
			padding: .5em;
			font-size: inherit;
			color: $brand_primary;
			cursor: pointer;

			i {
				margin-left: .5em;
				width: 1.25em;
			}
        }
        
		#help-text {
            opacity: 1;
            transform: translateY(0);
            transition: all .25s ease;
            overflow: visible;
            padding: 0em 1em;

            & ul {
                margin-top: 1rem;
                margin-bottom: 0rem;
                
                & li {
                    margin-top: .5rem;
                    margin-bottom: 0rem;
                }
            }

			&.help-text-hidden {
				height: 0;
				opacity: 0;
				transform: translateY(-20px);
				transition-duration: 0;
				overflow: hidden;
			}
		}
    }

    .modal-footer {
        justify-content: space-between;
        background-color: $gray-lightest;
        border-bottom-left-radius: .3rem;
        border-bottom-right-radius: .3rem;
        border-top: 1px solid darken($gray-lightest, 3%);
    }

	// <Links /> 
	a {
		color: $brand_primary;
		&:hover {
			color: darken($brand_primary, 10%);
			text-decoration: none;
		}
	}
	
	.disabled {
		opacity: 0.7;
		pointer-events: none;
	}
    
	.btn-outline-secondary {
		color: $gray;
		border-color: $gray-light;
		&:hover {
			color: $gray;
			border-color: $gray;
		}
    }

    .modal-dialog {
        &::after {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            content: '';
            background: rgba(255,255,255,0.5);
            border-radius: .3rem;
            opacity: 0;
            pointer-events: none;
            z-index: 10;
            transition: opacity .7s ease;
        }
        
        &.loading::after {
            opacity: 1;
            transition-duration: .2s;
        }  
    }

    & .form-control {
        color: $gray;
        border: 1px solid $teal;
        transition: all .2s ease;

        &:disabled {
            // color: lightgray !important;
            cursor: not-allowed;
            color: $gray-light !important;
            border: 1px solid #eee;
            font-style: italic;
            font-weight: 300;
        }

        &:hover:enabled {
            color: $gray-dark;
            border: 1px solid darken($teal, 5%) !important;
        }

        &:focus {
            color: $gray-dark;
            outline: none;
            border: 1px solid $teal !important;
        }
    }
}

.fade.in {
	opacity: 1;
}

.modal.in .modal-dialog {
	-webkit-transform: translate(0, 0) !important;
	-o-transform: translate(0, 0) !important;
	transform: translate(0, 0) !important;
}

.modal-backdrop .fade .in {
	opacity: 0.5 !important;
}

.modal-backdrop.fade {
	opacity: 0.5 !important;
}