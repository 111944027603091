@mixin button-style() {
  font-size: .9rem;
  margin: .5rem;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: .5rem .2rem;
}

@mixin empty-button() {
  background: transparent;
  color: white;
  border-style: dashed;
}

@mixin __transitions($time) {
  -moz-transition: all $time ease-in-out;
      transition: all $time ease-in-out;
}

.dbedit {
  margin-top: 55px;
  min-height: calc(100vh - 55px);
  display: flex;
  justify-content: space-between;

  &-landing {
    &-sidebar {
      &-layout {
        background: #2e323c;
        flex: 1.2;
        & > h6 {
          border-top: 1px solid #373c47;
          border-bottom: 1px solid #373c47;
          height: 50px;
          padding: 1rem 1rem 0;
          font-weight: 700;
          text-transform: uppercase;
          text-align: center;
        }
        & .dashboard-marker-links {
          display: flex;
          height: 50px;
          padding: 1rem 1rem 0;
          justify-content: space-around;
          a:hover { text-decoration: none; }
          h6 {
            color: $gray;
            cursor: pointer;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            padding: 0 1rem 7px;
            &.selected {
              color: white;
              border-bottom: 2px solid $app-yellow;
            }
          }
        }
      }
    }
  }

  &-sidebar-dashboards {
    display: flex;
    flex-direction: column;

    .no-defaults-selected {
      font-weight: 500;
      font-size: .9rem;
      color: #6f7890;
      font-style: italic;
      padding: .75rem 1.25rem;
      margin: 0;
      height: 50px;
    }

    h6 {
      padding: 0 1.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: none;
      border-bottom-color: $app-yellow;
      margin-bottom: 1px;
    }

    .list-group {
      overflow-y: auto;
      max-height: 300px;
      &-defaults {
        // min-height: 250px;
        margin-bottom: 2rem;
        .list-defaults {
          &-enter{
            opacity: 0.01;
            top: 0;
          }
          &-enter.list-defaults-enter-active {
            opacity: 1;
            @include __transitions(500ms);
          }
          &-exit {
            opacity: 1;
            top: 0px;
          }
          &-exit.list-defaults-exit-active {
            opacity: 0.01;
            top: 50px;
            @include __transitions(300ms);
          }
        }
      }
      &-selects{
        .list-selects {
          &-enter, &-appear{
            opacity: 0.01;
            top: 0;
          }
          &-appear.list-selects-appear-active,
          &-enter.list-selects-enter-active {
            opacity: 1;
            @include __transitions(500ms);
          }
          &-exit {
            opacity: 1;
            top: 0px;
          }
          &-exit.list-selects-exit-active {
            opacity: 0.01;
            top: -50px;
            @include __transitions(300ms);
          }
        }
      }
    }
    .list-group-item {
      cursor: pointer;
      min-height: 50px;
      font-size: 1rem;
      border-right: none;
      display: flex;
      justify-content: space-between;
      transition: background-color .2s ease-in;
      &.cursor-default {
        cursor: default;
      }
      .fa {
        cursor: pointer;
      }
      .fa-star {
        color: $brand-info;
        font-size: 12px;
        margin-left: .3rem;
      }
      .list-title-div{
        position: relative;
        .draggable {
          cursor: move; /* fallback if grab cursor is unsupported */
          cursor: grab;
          cursor: -moz-grab;
          cursor: -webkit-grab;
          position: absolute;
          left: -15px;
          top: 3px;
          padding: 0 3px;
          color: $gray-dark;
          font-size: 1.2rem;

          &.draggable:hover {
            color: #cfd2da;
          }

          &.draggable:active {
            color: $brand-success;
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
          }
        }
      }
      &:first-child {
        border-top: none;
      }
      &.option:hover {
        background: darken(#3a3f4b, 3%);
      }
    }

    .selected {
      background: darken(#3a3f4b, 1%);
    }
    .unsaved {
      font-style: italic;
    }
  }

  &-sidebar-tag-info {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    padding: 1rem;

    .field {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0 0 1rem;

      input {
        @include input-style(1rem, white, $gray-dark);
      }

      button {
        .fa-circle {
          margin-right: 1rem;
        }
      }

      .dropdown {
        margin-top: .25rem;
        &-menu {
          overflow-y: auto;
          max-height: 50vh;
          button {
            display: flex;
            align-items: center;
            padding: 3px .6rem;
            text-transform: capitalize;
          }
        }
      }
    }

    .btn {
      width: 110px;
      &:focus {
        border-color: $app-yellow;
      }
    }
    .btn-delete-tag {
      align-self: flex-end;
    }
  }

  &-content {
    &-subhead {
      display: flex;
      flex-direction: column;
      flex: 4;

      h6 {
        font-weight: 600;
        margin-bottom: 0;
      }
    }
    &-dbtags {
      min-height: 220px;
      padding: 2rem;
      // border-bottom: 3px solid #373c47;

      &-inputs {
        display: flex;
        justify-content: space-between;

        &-title {
          min-width: 250px;
          margin-bottom: 2rem;
          display: flex;
          flex-direction: column;
        }

        &-note {
          min-width: 250px;
          margin-bottom: 1rem;
          display: flex;
          flex-direction: column;
        }
        
        & .btn:focus {
          border-color: $app-yellow;
        }

        input {
          @include input-style(1rem, white, $gray-dark);
        }

        #delete-button {
          height: 35px;
          overflow: visible;
        }
      }

      &-dbTagDiv {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media (min-width: 1250px) {
          margin: 0 3.33%;
        }
        @media (min-width: 1500px) {
          margin: 0 8.33%;
        }
        @media (min-width: 1650px) {
          margin: 0 15%;
        }
        @media (min-width: 2000px) {
          margin: 0 20%;
        }
        .btn {
          @include button-style();
          &-empty {
            @include empty-button();
          }
        }
      }
    }

    &-alltags {
      display: flex;
      flex-direction: column;

      &-title {
        display: inherit;
        border-bottom: 2px solid #2e323c;
        // background: #373c47;
        min-height: 50px;
        text-transform: uppercase;

        & h6 {
          align-self: center;
          font-weight: 700;
          padding-left: 2rem;
        }
      }

      &-div {
        overflow-y: auto;
        max-height: calc(100vh - 379px);
        padding: 2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      .btn {
        @include button-style();

        &-empty {
          @include empty-button();
        }
      }
    }

    &-messages-div {
      min-height: 50px;
      background: #373c47;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  #dbedit-content-messages {
    margin: 0;
    text-align: center;
    font-style: italic;
  }
  #h2-large {
    color: transparentize($gray, .4);
    font-size: 2rem;
    font-weight: 600;
  }
  &-default-picker {
    .picker-div {
      display: flex;
      justify-content: space-between;
    }
    .default-disclaimer {
      font-weight: 400;
      margin-top: 5px;
      text-transform: initial;
      font-style: italic;
      color: $gray-light;
      font-size: .80rem;
      letter-spacing: .01rem;
      line-height: 1rem;
      // color: rgb(173, 178, 191);
    }
    i {
      cursor: pointer;
    }
  }
}

