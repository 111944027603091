/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

// scrollbar styles
*::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 2px;
	background-color: transparent;
}

*::-webkit-scrollbar {
	width: 8px;
	background-color: transparent;
}

*::-webkit-scrollbar-thumb {
	border-radius: 2px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: $gray-dark;
}

body {
	height: 100%;
}

/** BUTTONS **/
.btn,
button,
button:hover {
    cursor: pointer;
    font-family: $font-family-base;
}

button.button-icon {
    font-size: .8rem;
    i {
        margin-right: .7em;
        font-size: 1rem;
    }
}

.btn.focus,
.btn:focus {
	outline: 0;
	box-shadow: 0 0 0 0px transparent;
}

.btn.confidential {
    border: 0px;
    background: transparent;
    padding: 2px;
    text-transform: uppercase;
    font-style: italic;
    font-size: 0.8rem;
    & > i {
        font-size: 1.4rem;
        padding-right: 0.15rem;
    }
}

/** CARDS **/
// .card-body {
//     -ms-flex: 1 1 auto;
//     flex: 1 1 auto;
//     min-height: 1px;
// 	padding: 1rem;
// }

// .card-img-top.logo {
// 	width: 90px;
// 	height: 90px;
// 	display: block;
// 	margin-left: auto;
// 	margin-right: auto;
// 	margin-top: 1rem;
// }

.msg {
	font-style: italic;

	&-error {
		color: $brand-danger;
	}

	&-success {
		color: $brand-success;
	}
}

.upper {
	text-transform: uppercase;
}

// used for the dismiss error button
#dismiss-error-btn {
	margin-left: 10px !important;
	color: gray;
	font-size: 0.8rem;
	cursor: pointer;
}

.cursor-input {
	cursor: text;
}
.cursor-pointer {
	cursor: pointer;
}
.cursor-default {
	cursor: default;
}

.cursor-not-allowed {
	cursor: not-allowed;
}

h1 {
	font-size: $font-size-h1; // 2.5rem
	margin: 0;
	color: $primary-color;
}
#h1-large {
	font-size: 4rem;
	margin: 0;
}
h2 {
	font-size: $font-size-h2; // 2rem;
	margin: 0;
}
#h2-large {
	font-size: 2.2rem;
	margin: 0;
	text-transform: uppercase;
}
h3 {
	font-size: $font-size-h3; // 1.75rem;
	//   margin: 0;
}
h4 {
	color: $gray-dark;
	font-size: $font-size-h4; // 1.5rem;
	//   margin: 0;
}
#h4-large {
	color: $gray-dark;
	font-size: 1.6rem;
	font-weight: 600;
}
h5 {
	color: $gray-dark;
	font-size: $font-size-h5; // 1.25rem;
	margin: 0;
}
h6 {
	font-size: $font-size-h6; // 1rem;
	margin: 0;
}
#h6-small {
	font-size: 0.7rem;
	font-weight: 600;
	margin-bottom: 0.5rem;
	color: $gray;
	text-transform: uppercase;
}
.h6-small {
	font-size: 0.7rem;
	font-weight: 600;
	margin-bottom: 0.5rem;
	color: $gray;
	text-transform: uppercase;
}
p {
	font-size: 0.9rem;
}
span {
	// font-size:
}

small {
	display: block;
	font-size: 0.8rem;
}

.copyright {
	position: absolute;
	font-size: 0.7rem;
	left: 5px;
	bottom: 5px;
	color: #51586a;
	font-weight: 600;
	text-transform: uppercase;
}

@mixin input-style($fontSize, $color, $borderColor) {
	flex: 1;
	color: $color;
	background: transparent;
	font-size: $fontSize;
	border: none;
	border-bottom: 1px solid $borderColor;
	width: 100%;
	font-weight: 100;
	outline: 0;
	// line-height: 0;
	font-family: Open Sans;
	&:focus {
		border-bottom-color: $app-yellow;
	}
	&:disabled {
		cursor: not-allowed;
	}
}

/** Animation & Transitions **/
// A color ease in
@-webkit-keyframes aColorEaseIn {
	from {
		color: transparent;
	}
	to {
		color: $text-muted;
	}
}
@keyframes aColorEaseIn {
	from {
		color: transparent;
	}
	to {
		color: $text-muted;
	}
}
.colorEaseIn {
	-webkit-animation-name: aColorEaseIn; /* Safari 4.0 - 8.0 */
	-webkit-animation-duration: 0.5s; /* Safari 4.0 - 8.0 */
	animation-name: aColorEaseIn;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;
}

// Zoom in and out
@keyframes zoomInOut {
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.2,1.2);
    }
    100% {
        transform: scale(1,1);
    }
}

@-webkit-keyframes zoomInOut {
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.2,1.2);
    }
    100% {
        transform: scale(1,1);
    }
}

.zoomInOut {
	-webkit-animation-name: zoomInOut; /* Safari 4.0 - 8.0 */
	-webkit-animation-duration: 0.5s; /* Safari 4.0 - 8.0 */
	animation-name: zoomInOut;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;
}


/** ellipsis loader **/
.ellipsis-loader {
	margin: 1rem;
  	text-align: center;
	
	& i {
	color: lighten($brand-primary, 2);
	padding-right: .8rem;
    opacity: 0;
    -webkit-animation: ellipsis-dot 1s infinite;
	animation: ellipsis-dot 1s infinite;
	
		&:nth-child(1) {
			-webkit-animation-delay: 0.0s;
			animation-delay: 0.0s;
		}

		&:nth-child(2) {
			-webkit-animation-delay: 0.1s;
			animation-delay: 0.1s;
		}

		&:nth-child(3) {
			-webkit-animation-delay: 0.2s;
			animation-delay: 0.2s;
		}
	}
}

@-webkit-keyframes ellipsis-dot {
      0% { opacity: 0; }
     50% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes ellipsis-dot {
      0% { opacity: 0; }
     50% { opacity: 1; }
    100% { opacity: 0; }
}