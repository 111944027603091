/* ------------------------------------------------------------ *\
	editor - 
\* ------------------------------------------------------------ */

.editor {
    padding: 0; 
    margin: 0;
    margin-top: .7rem;

    &-ql-btns {
        /* text-align: right; */
        float: right!important;
        margin-top: 7px;
        margin-right: 16px!important;
        font-size: 1.1rem;
        
        & i {
            color: #4d4d4d;
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;

            &:hover {
                color: darken($blue, 10%);
            }

            &.saving, .resetting {
                color: grey;
            }

            &.saving::after, .restting::after {
                opacity: 1;
                transition-duration: .2s;
            }
        }
        & :first-child {
            margin-right: 15px;
        }
        & :last-child {
            margin-right: 0px;
        }
    }

    .quill {
        max-height: inherit;
        transition: all .1s linear;
    }

    .ql-container {
        border-bottom-left-radius: 0.3em;
        border-bottom-right-radius: 0.3em;

        &.ql-bubble {
            border-top-left-radius: 0.3em;
            border-top-right-radius: 0.3em;
        }

        background: #fefcfc;
        min-height: 110px;
        height: 100%;
        max-height: inherit;
        overflow-y: auto;
    }

    .ql-editor {
        & p, h1, h2, h3, ul, ol, li {
            color: #000;
        }
    }

    /* Snow Theme */
    .ql-snow.ql-toolbar {
        display: block;
        border-top-left-radius: 0.3em;
        border-top-right-radius: 0.3em;
        // border-bottom: 1px solid #f5f5f5;
        background: #eaecec;
        font-family: sailec light,sans-serif;
      }
}