// DO NOT CHANGE HEIGHTS / WIDTHS IN HERE, WILL ALTER ALL SLIDERS
// TODO: Make it so heights/widths can be chosen inside the slider widget itself
.switch-container {
	label {
		margin: 0;
	}
	// sets the regular checkbox to be invisible, as we just need it's functionality, not looks
	input[type="checkbox"].switch  {
		display: none;
	}
	// Changes: Background
	// When: Always. (will update when checked with different styles)
	// Notes: append :checked to .switch in order to alter the checked styling
	input[type="checkbox"].switch + div {
		background-color: transparent;
		vertical-align: middle;
		width: 28px;
		height: 14px;
		border-radius: 9px;
		-webkit-transition-duration: .4s;
		transition-duration: .4s;
		-webkit-transition-property: background-color, box-shadow;
		transition-property: background-color, box-shadow;
		cursor: pointer;
	}

	// Changes: Inner circle
	// When: Always. (will update when checked with different styles)
	input[type="checkbox"].switch + div > div {
		background: $primary-color;
		float: left;
		width: 18px; height: 18px;
		border-radius: inherit;
		-webkit-transition-timing-function: cubic-bezier(1,0,0,1);
		transition-timing-function: cubic-bezier(1,0,0,1);
		-webkit-transition-duration: 0.4s;
		transition-duration: 0.4s;
		-webkit-transition-property: transform, background-color;
		transition-property: transform, background-color;
		pointer-events: none;
		// margin-top: 1px;
        // margin-left: 1px;
        margin: 0px;
	}

	// Changes: Inner circle.
	// When: When checked
	input[type="checkbox"].switch:checked + div > div {
		-webkit-transform: translate3d(14px, 0, 0);
		transform: translate3d(14px, 0, 0);
	}

	// adds border
	input[type="checkbox"].switch + div {
		border: 1px solid $app-yellow;		
		height: 20px;
		width: 34px;
	}
}