#analysis-data-visualizer {
	position: relative;
	margin-top: .7rem;
	padding-top: 10px;
	background-color: rgba(0,0,0,0.1);
	overflow-y: auto;
	
	&.loading {
		display: flex;
		justify-content: center;
		align-items: center;
			

		i.loading-indicator {
			display: block;
			font-size: 5rem;
			color: darken($gray, 20%);
		}
	}

	&.has-left-y-axis-label {
		padding-left: 40px;
	}

	// Shared props for both shadows
	&::before,
	&::after {
		position: sticky;
		display: block;
		width: 100%;
		height: 20px;
		content: "";
		opacity: 0; // Transparent unless appropriate class is applied.
		z-index: 100;
		pointer-events: none;
		transition: opacity .1s ease
	}

	// Top shadow for container
	&::before {
		top: 0;
		margin-bottom: -20px; // Makes up for the element height
		background: radial-gradient(farthest-side ellipse at top, rgba(0,0,0,0.5), transparent);
	}
	&.top-shadow::before {
		opacity: 1;
		transition-duration: .25s;
	}

	// Bottom shadow
	&::after {
		bottom: 0;
		margin-top: -20px; // Makes up for the element height
		background: radial-gradient(farthest-side ellipse at bottom, rgba(0,0,0,0.5), transparent);
	}
	&.bottom-shadow::after {
		opacity: 1;
		transition-duration: .25s;
	}

	#data-visualizer-charts {
		//padding-bottom: 1.5rem;
		margin-bottom: 1rem;
		border-bottom: 1px solid rgba(0,0,0,0.2);

		.VictoryContainer > svg {
			overflow: visible;
		}

		h4 {
			margin-bottom: 1.5em;
			font-size: .8rem;
			text-align: center;
			color: $gray;
		}
	}
	.datavisualizer-legend-container {
		ul.legend {
			overflow: hidden; // Clears <li> floats
			list-style: none;
			padding: 0 0 0 3%;

			> li {
				float: left;
				margin: 0 2.5em .5em 0;
				font-size: .75rem;

				.color {
					margin-right: .75em;
					border-radius: 1em;
				}
			}
		}
	}
	button.dataset-upload-button {
		display: block;
		margin: 0 0 1rem 3%;
	}
}