/* ------------------------------------------------------------ *\
	form
\* ------------------------------------------------------------ */
@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.form {
	&-checkbox {
		cursor: pointer;
		font-size: 1rem;
  		font-weight: 500;

		&.fa-check-square {
			color: $brand-teal;
		}	

		&.fa-square {
			color: $gray;
		}

		&.disabled, &:hover.disabled {
			cursor: not-allowed;
			color: $gray-light;
		}

		&:hover {
			color: $brand-primary;
		}
	}

	&-numbers {
		color: #6f7890;
		background: transparent;
		font-size: 1rem;
		border-top: none;
		border-right: none;
		border-bottom: 1px solid #5bc0de;
		border-left: none;
		border-image: initial;
		font-weight: 100;
		outline: 0;
		font-family: Open Sans;
		margin-right: 5px;
		max-width: 40px;
		text-align: center;

		&.disabled {
			cursor: not-allowed;
			color: $gray-light;
			border-bottom: 1px solid $gray-light;
		}
	}

	&-text, &-code {
		color: #6f7890;
		background: transparent;
		font-size: 1rem;
		border-top: none;
		border-right: none;
		border-bottom: 1px solid #5bc0de;
		border-left: none;
		border-image: initial;
		font-weight: 100;
		outline: 0;
		font-family: Open Sans;
		margin-right: 5px;
		padding: 0 5px;

		&:disabled {
			cursor: not-allowed;
			color: $gray-light;
			border-bottom: 1px solid $gray-light;
		}
	}

	&-code { 
		cursor: pointer;
		font-family: Courier; 
		font-size: 0.95rem; 
	}

	&-access {
		border-top: 1px solid $gray-hr;
        border-bottom: 1px solid $gray-hr;
        margin-top: 1rem;
		padding: 1.5em 0;

		& .btn {
			background: $brand-white;
		}

		& .btn-danger {
			background: #e64759;
		}

		& .form-control {
			background: #434857;
			transition: background 0.15s;
		}

		& .form-control:focus {
			background: #4c5262;
			color: #fff;
			border: 1px solid rgba(0, 0, 0, 0.15);
		}

		& .form-group.consent {
			display: flex;
			justify-content: center;

			& .form-control {
				display: inline;
                width: inherit;
            }

            & .info {
                margin-left: 0.5rem;
			}

			& .text-danger {
				display: inline;
				font-weight: 800;
            }
            
            & > div > span {
                margin-right: 0.8rem;
                margin-left: 0.1rem;
            }
        }

        & select.form-control:not([size]):not([multiple]) {
            height: 2.25rem;
            color: #d5d8df;

            /* This section calls the slideInFromLeft animation we defined above */
            animation: 1s ease-out 0s 1 slideInFromTop, fadeIn 2s  ;
            -webkit-animation: 1s ease-out 0s 1 slideInFromTop, fadeIn 2s; /* Safari 4+ */
            -moz-animation:    1s ease-out 0s 1 slideInFromTop, fadeIn 2s; /* Fx 5+ */
            -o-animation:      1s ease-out 0s 1 slideInFromTop, fadeIn 2s; /* Opera 12+ */
			animation:         1s ease-out 0s 1 slideInFromTop, fadeIn 2s; /* IE 10+, Fx 29+ */
			
			& option:disabled {			// Only works in Firefox
				color: #d5d8df;
				font-weight: 600;
			}
        }
	}

	&-settings {
		padding-top: 0.5rem;
	}
	&-settings .btn {
    }
    
	&-settings .form-control {
		background: #434857;
		transition: background 0.15s;
		&::placeholder {
			color: $gray;
			font-style: italic;
		}
	}
	&-settings .form-control:focus {
		background: #4c5262;
		color: #fff;
		border: 1px solid rgba(0, 0, 0, 0.15);
	}
	&-settings textarea {
		height: 86px;
	}
	&-settings &-actions .btn {
		margin: 0 5px;
		min-width: 90px;
	}

	
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus input:-webkit-autofill,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: 1px solid #fff;
		-webkit-text-fill-color: #fff;
		-webkit-box-shadow: 0 0 0px 1000px #4c5262 inset;
		transition: background 0.15s;
	}
}
