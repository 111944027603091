//
// Text
//

// Alignment

.text-justify  { text-align: justify !important; }
.text-nowrap   { white-space: nowrap !important; }
.text-truncate { @include text-truncate; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}

// Transformation

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }

// Weight and italics

.font-weight-normal { font-weight: $font-weight-normal; }
.font-weight-bold   { font-weight: $font-weight-bold; }
.font-italic        { font-style: italic; }

// Contextual colors

// .text-white {
//   color: #fff !important;
// }

@include text-emphasis-variant('.text-gray', #bebebe);

@include text-emphasis-variant('.text-muted', $text-muted);

@include text-emphasis-variant('.text-primary', $brand-primary);

@include text-emphasis-variant('.text-success', $brand-success);

@include text-emphasis-variant('.text-info', $brand-info);

@include text-emphasis-variant('.text-warning', $brand-warning);

@include text-emphasis-variant('.text-danger', $brand-danger);

@include text-emphasis-variant('.text-yellow', $app-yellow);

@include text-emphasis-variant('.text-white', $brand-white);

@include text-emphasis-variant('.text-default', $brand-default);

@include text-emphasis-variant('.text-plum', $brand-plum);

@include text-emphasis-variant('.text-tangerine', $brand-tangerine);

@include text-emphasis-variant('.text-teal', $brand-teal);

@include text-emphasis-variant('.text-lime', $brand-lime);


// Font color

@include text-emphasis-variant('.text-gray-dark', $gray-dark);

// Misc
.text-hide {
  @include text-hide();
}

.text-disabled {
    color: $gray-light;
    font-style: italic;
}
