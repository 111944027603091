.video-react {
     font-family: Arial, Helvetica, sans-serif !important; 
     font-size: 11px
}

.video-react-control-bar, 
.video-react-control-text, 
.video-react-progress-control, 
.video-react-control{
     font-family: Arial, Helvetica, sans-serif; 
     font-size: 11px
}