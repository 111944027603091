/* ------------------------------------------------------------ *\
	grade
\* ------------------------------------------------------------ */

.grade {

	& input {
		color: white;
		background: transparent;
		font-size: 1rem;
		border-top: none;
		border-right: none;
		border-bottom: 1px solid #5bc0de;
		border-left: none;
		border-image: initial;
		font-weight: 100;
		outline: 0;
		font-family: Open Sans;
		margin-right: 5px;
		max-width: 40px;
	}
}