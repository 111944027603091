/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
	color: $primary-color;

	&.dark {
		color: #cfd2da;
	}

	&-header {
		border-bottom: 1px solid $gray-hr;
		display: flex !important;
		flex-direction: row;
		flex-wrap: nowrap;
		// flex-shrink: 50;
		font-weight: 400;
		justify-content: space-between;
		width: 100%;

		&.dark {
			border-bottom: 1.5px solid #51586a;
		}

		& .header {
			&::after {
				font-family: "Font Awesome\ 5 Pro";
				display: inline-block;
				padding-left: 6px;
				vertical-align: middle;
				font-size: 0.7rem;
			}
		}
		& .descSort::after {
			content: "\f063";
		}

		& .ascSort::after {
			content: "\f062";
		}
	}

	&-row {
		border-bottom: 1px solid $gray-hr;
		display: flex !important;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		vertical-align: middle;
		width: 100%;
		line-height: 35px;

		&:hover {
			background-color: $gray-hover;
		}


		&.dark {
			border-bottom: 1px solid $primary-color;
			&:hover {
				background-color: transparent;
			}
		}

		& .cell {
			overflow: hidden;
			padding: 0.2rem 0.5rem;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		& .contextMenu {
			& .dropdown-menu {
				line-height: initial;
				margin-top: 0;
				top: unset;
			}
		}

		small {
			line-height: 0.8rem;
			color: #cfd2da;
			margin-bottom: 0.5rem;
		}
	}
}

/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */
table {
	display: grid;
	border-collapse: collapse;
	min-width: 100%;

	/* From old table */
	max-width: 100%;
	margin-bottom: 1rem;
	color: $primary-color;
	background-color: #fff;
	/* End from old table */

	/* Define grid-template-columns & grid-template-rows inline */


}

thead,
tbody,
tr {
	display: contents;
}

thead {
	font-weight: 400;
	position: sticky !important;
	position: -webkit-sticky !important;
	top: 0;
	z-index: 1;
	background-color: inherit;

	& tr {
		background-color: inherit;
	}
}

th, td {
	padding: 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

th {
	position: sticky !important;
	position: -webkit-sticky !important;
	top: 0;
	z-index: 1;
	text-align: left;
	position: relative;
	border-bottom: 1px solid $gray-hr;
	background-color: inherit;

	& .btn-link {
		padding: 0
	}

	&::after {
			font-family: "Font Awesome\ 5 Pro";
			display: inline-block;
			padding-left: 6px;
			vertical-align: middle;
			font-size: 0.7rem;
		}

	&.descSort::after {
		content: "\f063";
	}

	&.ascSort::after {
		content: "\f062";
	}

	&:hover .resize-handle {
		opacity: 0.3;
	}

	& .resize-handle {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		background: $gray-hr;
		opacity: 0;
		width: 2px;
		cursor: col-resize;
	}

	& .resize-handle:hover,
	/* The following selector is needed so the handle is visible during resize even if the mouse isn't over the handle anymore */
	&.header--being-resized .resize-handle {
		opacity: 0.5;
	}

	//&:last-child {
		// 	border-bottom: 1px solid $gray-hr;
		// }
}

td {
	padding-top: 10px;
	padding-bottom: 10px;
	color: #808080;

	& .contextMenu {
		& .dropdown-menu {
			line-height: initial;
			margin-top: 0;
			top: unset;
		}
	}

	small {
		line-height: 0.8rem;
		color: #cfd2da;
		margin-bottom: 0.5rem;
	}
}

tr:nth-child(even) td {
	background: #f8f6ff;
}
