/* ------------------------------------------------------------ *\
	Settings
\* ------------------------------------------------------------ */
@mixin basic-border($padding, $color) {
	border-top: 1px solid $color;
	padding-top: $padding;
}
@mixin font-margin-upgrade($size) {
	font-size: ($size);
	margin: 0;
}
@mixin button-colors($font-color, $background-color) {
	background: $background-color;
	color: $font-color;
	border: $background-color;
}

// used in Account, Payment, Profile, Upgrade, Members, Maintenance, and NotFound
.settings {
    padding: 50px 5rem 0px;
	// text-align: center;
	display: flex;
    flex-direction: column;
    
    &-wide {
        padding: 50px 0px 0px;
	}
	
    &-medium {
        margin-left: auto;
        margin-right: auto;
        max-width: 1000px;
        min-width: 700px;
    }

    &-narrow {
        margin-left: auto;
        margin-right: auto;
        max-width: 800px;
        min-width: 700px;
    }

    &-head {
        text-align: center;
    }

	&-body {
        text-align: left;
                 
		.next-payment {
			strong {
				text-transform: capitalize;
			}
			p {
				text-align: center;
				margin: 0;
			}
			.plan-links {
				margin-top: 1rem;
				display: flex;
				justify-content: space-between;
				font-style: italic;
			}
        }
    }
    
    &-disabled {
        position: inherit;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1040;
        background-color: #fff;
        opacity: .5!important;
    }

    &-nav {
        padding-top: 120px;
        padding-left: 30px;
        height: 100vh;
        // border-right: 1px solid #e8e6e6;
        // background: #fdfbfb;

        a {
            align-items: center;
            border-left: 2px solid transparent;
			color: $primary-color;
			display: flex;
            font-size: 1.1rem;
			justify-content: flex-start;
			margin: 10px 0;
			padding: 0 5px;

            &:hover {
                text-decoration: none;
            }

            &.active {
                border-left: 2px solid #fcd500;
                font-weight: 400;
                text-decoration: none;
            }

            & i {
                margin: 0 .5rem;
                font-size: .9rem;
            }
        }


	}
	
	&-integrations {
		padding-top: 2rem;
	}

	.company {
		font-weight: 600;

		&-logo {
			width: 30px;
			height: 30px;
			margin-right: .5rem;
		}
	}

    &-preferences {
		color: $primary-color; 
		
		& .intergration {  // actually for integrations...
			border-top: 1px solid $gray-hr;
			margin-bottom: 1rem;
		}

        & .header {
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.8rem;
            margin-top: .8rem;
        }

        & button > i {
            color: #fff !important;
            padding-left: 0px !important;
        }

        & .container {
            display: 'flex';
            flex-direction: 'column';
        }

        & .preference {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 0.5rem;


        }

        & .info-icon {
            padding-left: 15px !important;
            color: $brand-teal !important;
            cursor: pointer;
        }

        & #help-text {
            opacity: 1;
            transform: translateY(0);
            transition: all .45s ease;
            overflow: visible;

            p {
                padding-left: 1.3em;
            }

            &.help-text-hidden {
				top: 0;
				opacity: 0;
                transform: translateY(-10px);
                transition: all .45s ease-in;
				overflow: hidden;

				 .col-12 {
					opacity: 1;
				 }
            }
		}
    }

    &-upgrade {
        align-content: center;

        &-plans {
            color: $primary-color;
            font-size: 1rem;
            text-align: left;
            margin-top: 0rem;
            display: flex;
            justify-content: center; //space-evenly;
        }

        &-plan {
            text-align: left;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 1.5rem;
            margin: 0.625rem;
            border-radius: 5px;

            h2 {
                letter-spacing: 1px;
                font-size: 1.3rem;
                font-weight: 400;
                text-transform: uppercase;
                color: $primary-color;
                padding-bottom: 10px;
                margin-bottom: 10px;

                &.red {
                    border-bottom: 4px solid $brand-danger;
                }
                &.green {
                    border-bottom: 4px solid $brand-success;
                }
                &.blue {
                    border-bottom: 4px solid $brand-primary;
                }
                &.plum {
                    border-bottom: 4px solid $brand-plum;
                }
                &.gray {
                    border-bottom: 4px solid $brand-gray;
                }
            }

            & hr {
                border-top: 1px solid 1px solid rgba(0, 0, 0, 0.2);
                margin-top: 2rem;
            }

            span, i, small {
                &.red {
                    color: $brand-danger;
                }
                &.green {
                    color: $brand-success;
                }
                &.blue {
                    color: $brand-primary;
                }
                &.plum {
                    color: $brand-plum;
                }
                &.gray {
                    color: $brand-gray;
                }
            }
            
            small {
                margin-top: 1.5rem;
                margin-bottom: 15px;
                text-align: left;
                strong {
                    letter-spacing: 2px;
                }
            }

            ul {
                list-style: none;
                margin: 0;
                padding-left: 0rem;
                padding-right: 0rem;

                &.advanced {
                    min-height: 210px;
                }

                & :last-child {
                    padding-bottom: 0;
                }
            }

            li {
                padding-bottom: 0.625rem;

                & > i {
                    margin-right: 12px;
                }
            }

            &-price {
                padding-bottom: 1rem;
                span {
                    font-size: 2.3rem;
                    font-weight: 500;
                }
                small {
                    display: inline;
                    vertical-align: super;
                    margin-left: 5px;
                }
            }

            &-button {
                text-align: center;
                min-height: 48px;
            }

            &-btn {
                width: 100%;
                height: 3rem;
                color: white;
                border: 1px solid;
                font-weight: 300;
                text-transform: uppercase;

                small {
                    font-size: 0.7rem;
                    text-align: center;
                    padding-top: 2px;
                }
                &-red {
                    background-color: $brand-danger;
                    &:hover {
                        background-color: lighten($brand-danger, 2%);
                    }
                }
                &-plum {
                    background-color: $brand-plum;
                    &:hover {
                        background-color: lighten($brand-plum, 7%);
                    }
                }
                &-green {
                    background-color: $brand-success;
                    &:hover {
                        background-color: lighten($brand-success, 5%);
                    }
                }
                &-blue {
                    background-color: $brand-primary;
                    &:hover {
                        background-color: lighten($brand-primary, 5%);
                    }
                }
                &-gray {
                    background-color: $brand-gray;
                    &:hover {
                        background-color: lighten($brand-gray, 5%);
                    }
                }
                &-disabled {
                    background-color: lighten($gray-light, 10%);
                    color: $gray;
                    border: 1px solid $gray-light;
                    &:hover {
                        cursor: not-allowed;
                        background-color: lighten($gray-light, 10%);
                        color: $gray;
                        border: 1px solid $gray-light;
                    }
                }
            }
        }

        .group {
            display: flex;
            flex-direction: row;
        }
    
        .toggle-switch {
            margin-top: 2rem;
            display: flex;
            justify-content: center;
            font-size: 12.8px;
            font-weight: 400;
            letter-spacing: 1px;
            // transition: all .25s ease;

            .highlighted {
                color: $primary-color;
                // font-weight: 400;
                transition: all .25s ease-in;
            }
            .notHighlighted {
                color: $gray-light;
                // font-weight: 400;
                transition: all .25s ease-in;
            }

            .slider {
                margin: 0 5px;
            }
        }

        .upgrade-notes {
            margin: 1rem;
            margin-left: 10%;
            margin-right: 10%;
            text-align: justify;
            
            & p > span {
                text-transform: uppercase;
                font-style: italic;
                border-bottom: 2px dashed #9f86ff; // #fcd500;
                margin-right: 5px;
                margin-top: 15px;
            }
        }   
    }


	.color-green {
		color: $brand-success;
		font-weight: 400;
	}
	.color-orange {
		color: $brand-tangerine;
		font-weight: 400;
	}
	.color-red {
		color: $brand-danger;
		font-weight: 400;
    }
	.color-gray {
		color: $brand-gray;
		font-weight: 400;
    }
	.color-blue {
		color: $brand-primary;
		font-weight: 400;
    }
	h1 {
		margin-bottom: 1rem;
		font-family: 'Raleway';
	}
	h5 {
		color: $primary-color;
		margin: 1.5rem;
		text-align: center;
	}
	p {
		color: $primary-color;
	}

	& .avatar {
		cursor: pointer;
	}
	& .avatar:after {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: '';
		opacity: 0;
		background: rgba(46, 47, 82, 0.3);
		z-index: 2;
	}
	& .avatar span {
		position: absolute;
		left: 0;
		top: 70px;
		right: 0;
		line-height: 20px;
		text-align: center;
		z-index: 3;
		opacity: 0;
	}
	& .avatar:hover {
		text-decoration: none;
		color: #fff;
	}
	& .avatar:hover:after,
	& .avatar:hover span {
		opacity: 1;
	}

	&-div-row {
		@include basic-border(1rem, $gray-hr);
	}
	&-align-right {
		text-align: right;
	}
	&-align-center {
		text-align: center;
    }
    
	a {
		color: $brand_primary;
		&:hover {
			color: darken($brand_primary, 10%);
			text-decoration: none;
		}
    }
}
