/* ------------------------------------------------------------ *\
	filterbar
\* ------------------------------------------------------------ */

.filterbar {
	padding: 12px 8px;
	display: flex;

	&-btn {
		margin: 0 4px;
	}

	.btn {
		font-size: 1rem;
		padding: 0.4rem 0.9rem;
	}

	&-group {
		position: relative;
		width: 100%;
		margin: 0 4px;
	}
	&-group .form-control {
        background-color: #434857;
        border-color: #434857;
        color: $gray-subtle;
        font-weight: 200;
        padding-right: 35px;

        &:focus {
            color: #FFF;
        }
	}
	&-group-icon {
		position: absolute;
		right: 0;
		top: 0;
		border-left: 1px solid rgba(0, 0, 0, 0.15);
		line-height: 35px;
		width: 35px;
		text-align: center;
	}

	.dropdown-item {
		display: flex;
		justify-content: space-between;
		padding-left: 2rem;
		i {
			align-self: center;
			padding-left: .5rem;
		}
		span {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.dropdown .dropdown-menu {
		margin-top: 0;
		overflow-y: scroll;
		max-height: 70vh;
	}
	.dropdown .dropdown-toggle::after {
		display: none;
	}
	.dropdown-menu {
		max-width: 240px;
	}
}
