/* ------------------------------------------------------------ *\
	dashboard
\* ------------------------------------------------------------ */

.dashboard {
	// should not need height
	// height: 100%;
	position: relative;
	// top pieces are 102px down
	top: 102px;
	&-logo {
		position: absolute;
		left: 15px;
		bottom: 15px;
		width: 108px;
		height: 11px;
	}
	&-content {
		// sidebar is 280px wide
		margin: 0 280px 0 0;
		// REMOVE PADDING
		// padding: 15px 0;
		height: calc(100vh - 102px);
		overflow-y: auto;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
	}
	&-content &-title {
		display: none;
		text-align: center;
	}

	&-subhead {
		position: fixed;
		left: 0;
		top: 54px;
		// sidebar is 280px wide
		right: 280px;
		background: #373c47;
		height: 48px;
		text-align: center;
	}

	&-actions {
		padding: 6px 15px;
		display: flex;
		float: left;
		min-width: 220px;
	}
	&-actions .btn {
		margin: 0 5px;
	}

	&-title {
		color: white;
		font-weight: bold;
		line-height: 28px;
		padding: 10px 0;
		margin: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&-sidebar {
		position: absolute;
		top: -48px;
		bottom: 0;
		right: 0;
		width: 280px;
		background: #2e323c;
		height: calc(100% + 48px);
	}
	&-sidebar-head {
		padding: 0 10px;
		border-bottom: 1px solid #4c5162;
		text-align: center;
	}
	&-sidebar-head h6 {
		color: white;
		font-weight: 600;
		text-transform: uppercase;
		padding: 15px 0;
	}
	&-sidebar-body {
		overflow-y: auto;
		padding: 10px;
		// 100% of the container component, minus 48px of the header
		height: calc(100% - 48px);
		& .collaborators {
			&-title {
				margin-bottom: 0.5rem;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&-space-between {
				display: flex;
			}
			& p {
				margin: 0;
				font-weight: 400;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			&-list {
				display: flex;
				flex-direction: column;
				& h5 {
					color: $gray-light;
					font-weight: 400;
					margin: 1rem 0;
					i {
						cursor: pointer;
						margin-right: 0.4rem;
					}
				}
			}
			& > p {
				&:first-child {
					margin-bottom: 5px;
				}
				text-decoration: underline;
			}
			& .collaborators-list-container {
				width: 100%;
				& ul li {
					color: $gray;
					font-style: italic;
				}
				&-ACCEPTED {
					& ul li {
						color: $gray-lighter;
						font-style: normal;
					}
				}
			}
			& .collaborators-list-subtitle {
				font-weight: 500;
				font-size: 12px;
				color: $gray;
				font-style: italic;
				margin-bottom: 10px;
				text-transform: uppercase;
			}
			& ul {
				display: flex;
				flex-direction: row;
				text-decoration: none;
				padding: 0 5px;
				flex-grow: 1;
				& .collaborators-list-items {
					width: 100%;

					small {
						color: $gray;
						padding: 0 32px;
					}
				}
				& li {
					padding: 5px 0;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					div {
						overflow: hidden;
						& p {
							overflow: hidden;
							text-overflow: ellipsis;
							font-weight: 400;
							margin-bottom: 5px;
							& span {
								font-weight: 100;
							}
						}
					}
					.sidebar-delete-container {
						overflow: visible;
					}
					& .hidden-content {
						display: none;
					}
				}
			}
		}
		&-delete {
			padding: 0 5px 5px;
			line-height: normal;
			background: transparent;
			border: none;
			color: $gray-light;
			font-size: 1rem;
			&:hover {
				color: $gray;
			}
		}
	}

	&-sidebar-handle {
		position: absolute;
		left: 100%;
		top: 0;
		bottom: 0;
		width: 1px;
		cursor: ew-resize;
	}
	&-sidebar-resizing {
		pointer-events: none;
	}
	&-sidebar-resizing &-sidebar-handle,
	&-sidebar-handle:hover {
		background: #4d5262;
	}

	@media (max-width: 767px) {
		top: 54px;
		&-logo {
			display: none;
		}
		&-subhead {
			position: relative;
			right: 0;
			top: 0;
		}
		&-content {
			height: auto;
			margin-right: 0;
			overflow: visible;
		}
		&-sidebar {
			position: static;
			width: auto;
			padding-top: 0;
		}
		&-sidebar-head {
			position: static;
		}
		&-sidebar-body {
			height: auto;
			overflow: visible;
		}

		&-content &-title {
			display: block;
		}
		&-subhead &-title {
			display: none;
		}
	}
}
