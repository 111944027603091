/* ------------------------------------------------------------ *\
	badge
\* ------------------------------------------------------------ */

.badge {
	margin: 0 .8em;
	font-size: 75%;
	padding: .25em .4em;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
	display: inline-block;
	//background: #fff;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
	border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	
	&-pill {
		border-radius: .75rem;
		padding-left: .6em;
		padding-right: .6em;
	}
	
	&-primary {
		color: $brand-primary;
		border: 1px solid $brand-primary;
	}
	&-success {
		color: $brand-success;
		border: 1px solid $brand-success;
	}
	&-danger {
		color: $brand-danger;
		border: 1px solid $brand-danger;
	}
	&-warning {
		color: $brand-warning;
		border: 1px solid $brand-warning;
	}
	&-info {
		color: $brand-info;
		border: 1px solid $brand-info;
	}
	&-default {
		color: $brand-default;
		border: 1px solid $brand-default;
	}
	&-plum {
		color: $brand-plum;
		border: 1px solid $brand-plum;
	}
	&-tangerine {
		color: $brand-tangerine;
		border: 1px solid $brand-tangerine;
	}
	&-teal {
		color: $brand-teal;
		border: 1px solid $brand-teal;
	}
}
