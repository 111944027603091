/* ------------------------------------------------------------ *\
	project
\* ------------------------------------------------------------ */
@mixin __transitions($time) {
  -moz-transition: all $time ease-in-out;
      transition: all $time ease-in-out;
}

.project {
	&-thumbs {
		padding-top: 10px;
		.row {
			padding: 10px;
			&>div {
			// we want width to be 235px, so we add an extra 20px for the padding
			min-width: 255px;
			width: 100%;
			}
		}
		// in order to get the flex to work, we need to get the
		// immediate div of the project-thumbs-transition-group
		&-transition-group > div {
			display: flex;
			flex-wrap: wrap;
			.project-thumb-fade {
				&-enter, &-appear{
				opacity: 0.01;
				// top: -100px;
				}
				&-enter.project-thumb-fade-enter-active,
				&-appear.project-thumb-fade-appear-active {
				opacity: 1;
				// top: 0;
				@include __transitions(500ms);
				}
				&-exit {
				opacity: 1;
				// left: 0px;
				}
				&-exit.project-thumb-fade-exit-active {
				opacity: 0.01;
				// left: -100px;
				@include __transitions(300ms);
				}
			}
		}
	}

	&-thumb {
		cursor: pointer;
		background: #d5d8df;
		border-radius: 1rem 1rem 0.5rem 0.5rem;
		color: $primary-color;
		user-select: none;
		width: 234px;
		margin: 0 auto 20px;
		border: 0;

		& span {
			float: left;
			margin-left: -40px;
		}

		&-image {
			height: 134px;
			// border: 2px solid #1c1f25;
			border-bottom: none;
			border-radius: 0.5rem 0.5rem 0 0;
			background-color: #1c1f25;
			// overflow: hidden;
			position: relative;
			text-align: center;
		
			& img {
				min-width: 100%;
				min-height: 100%;
				max-height: 100%;
			}

			&.icon {
				// border-radius: 0.5rem;
				border: 2px solid #6f7890;
				border-bottom: none;
				&.moodle {
					border-color: $brand-moodle-darker;
					// & i.thumbnail {
					// 	color: $brand-moodle-darker
					// }
				}
				& i.thumbnail {
					font-size: 5rem;
					padding-top: 2rem;
					color: #6f7890;
				}
			}
		}

		& .card-block {
			border-top: 1px solid #c7c7c7;
			padding: 0.75rem;
		}

		&-title {
			margin-bottom: 5px;

			p {
				margin: 0;
				color: $primary-color;
				font-weight: bold;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			& .form-control {
				display: none;
				padding: 3px 8px;
			}

			&-editing {
				.form-control {
					display: block;
				}

				p {
					display: none;
				}
			}
		}

		&-details {
			padding-left: 40px;

			p {
				font-size: 0.8rem;
				padding-top: .4rem;
			}

			img {
				float: left;
				margin-left: -40px;
				}
		}

		&-user {
			float: left;
			margin-left: -40px;
			width: 32px;
			height: 32px;
			border-radius: 50%;
		}
	  
		&-status {
			position: absolute;
			right: 0.75rem;
			bottom: 0.35rem;
		
			> i {
				margin-left: 8px;
				color: #f0f2f6;
				text-shadow: 0px 1px 0px rgba(0,0,0,.16);
			}
			> i:disabled, i[disabled]{
				cursor: not-allowed;
			}
		}

		&-assignment-title {
			position: absolute;
			left: .75rem;
			bottom: .35rem;
			font-size: 0.85rem;
			font-style: italic;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 170px;
		}
		
		&-selected {
			border-radius: 0.5rem;
			border-color: $app-yellow;
			box-shadow: 0 0 0 2px $app-yellow;

			.project-thumb-image.icon {
				border-color: transparent;
			}

			.project-thumb-image.icon.moodle {
				box-shadow: 0 0 0 2px $brand-moodle;
			}
		}
		
		&-overlay {
			position: relative;

			&:after {
				cursor: no-drop;
				background-color: rgba(255, 255, 255, 0.8);
				position: absolute;
				content:"";
				top:0;
				left:0;
				width:100%;
				height:100%;
				opacity:.7;
				border-radius: .5rem;
			}
		}

		&-menu {
			display: none;
			position: absolute;
			left: 0.75rem;
			top: 0.75rem;
			z-index: 3;
		
			.show>.dropdown-menu {
				margin: 0;
			}
			
			&-btn {
				color: #fff;
				background-image: none;
				background: rgba(0, 0, 0, 0.3);
				border-color: 1px transparent;
				margin-bottom: .5px;
			}
		}

		&-menu-right {
			position: absolute;
			right: .75rem;
			top: 0.75rem;
			z-index: 3;
		
			.show>.dropdown-menu {
				margin: 0;
				top: 95%;
			}

			.dropdown-menu-right {
				right: -25px;
			}

			.dropdown-header {
				padding: .1rem 1.5rem;
				color: $primary-color;
				font-weight: 400;
				cursor: default;
			}

			& .lti-logo {
				max-width: 30px;
				max-height: 30px;
				margin-left: 1rem;
	
				&.greyscale {
					-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
					filter: grayscale(100%);
				}
			}
		}
	}

	&-thumb:hover &-thumb-menu {
		display: block;
	}
}
