$timeline-gray: #2e323c;
$timeline-big-line-color: #cfd2da;

#timeline-wrapper {
  overflow: auto;
  .timeline {
    &-row {
      margin: 15px 0;
      &-title {
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 0 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &-main-timer {
      position: relative;
      border: 1px solid transparent;
      background: $timeline-gray;
      // if you change this height, change the ticker-base-style of both big / small, and the slider-container
      height: 30px;
      margin-top: 5px;
      &-slider {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid $timeline-big-line-color;
        position: absolute;
        z-index: 5;
        display: flex;
      }
    }
    &-slider-container {
      position: relative;
      height: 15px;
      .timeline-individual-slider {
        height: 100%;
        position: absolute;
        display: flex;
        user-select: none;
        border: 1px solid transparent;
        border-radius: 15%;
        &.selected {
          z-index: 100;
          border-color: $app-yellow;
        }
        .draggable-center {
          color: black;
          width: 100%;
          height: 100%;
          font-size: 8px;
          font-weight: 800;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .drag {
          padding: 0 2px;
          height: 100%;
          position: absolute;
          cursor: ew-resize;
          // needs to be 1 above .draggable-center in order to allow dragging of corners
          z-index: 2;
          &-left {
            left: 0;
          }
          &-right {
            right: 0;
          }
        }
      }
      .container-line-through {
        background: black;
        width: 100%;
        height: 4px;
        position: absolute;
        top: 50%;
      }
    }
  }
  .ticker-base-style {
    border-left: 1px solid #cfd2da;
    position: absolute;
    font-size: .7rem;
    top: 0;
    user-select: none;
    &-big {
      padding-left: 2px;
      line-height: 1;
      // 1/2 the size of the timeline-main-timer height
      height: 15px;
      &-numbers {
        position: absolute;
        bottom: -13px;
        font-size: .6rem;
        right: 0;
      }
    }
    &-small {
      // 1/4 the size of the timeline-main-timer height
      height: 7.5px;
    }
  }
  #timeline-big-line {
    background: $timeline-big-line-color;
    // minus the 6px top offset
    height: calc( 100% - 6px );
    width: 2px;
    // 5px to offset margin, 1px for border
    // offset by 10px, since that's how tall the 
    top: 6px;
    position: absolute;
    z-Index: 4
  }
}