.markerEdit{
  display: flex;
  justify-content: space-around;
  input {
    @include input-style(1rem, white, $gray-dark);
    &::placeholder {
      font-style: italic;
      color: $gray-dark;
    }
  }
  ul {
    max-height: calc(100vh - 105px);
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    min-width: 210px;
    .list-group-item {
      min-height: 40px;
    }
  }
  #h2-large {
    color: transparentize($gray, .4);
    font-size: 2rem;
    font-weight: 600;
  }
  #h6-small {
    margin: 0;
  }
  &-markers {
    & > h6 {
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: .5rem;
    }
    .list-group-item {
      font-size: 1rem;
      border-right: none;
      cursor: pointer;
      &.active {
        background-color: #2e323c;
      }
    }
    #addMarker {
      display: flex;
      justify-content: space-between;
    }
  }
  &-options {
    .list-group-item{
      border: none;
      padding: 0;
      button{
        padding: .2rem .6rem;
        border-color: transparent;
        margin-left: .25rem;
        color: $gray;
      }
    }
    .btn:focus {
      border-color: $app-yellow;
    }
  }
  &-noneSelected, &-loading{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 220px;
  }
  .btn-outline-success {
    margin-top: 2rem;
    padding: 6px 30px;
    border-color: darken($brand-success, 10%);
    &:focus, &:hover {
      color: darken($brand-success, 20%);
      border-color: darken($brand-success, 20%);
    }
  }
  &-default-picker {
    margin-top: 2rem;
    .picker-div {
      display: flex;
      justify-content: space-between;
    }
    .default-disclaimer {
      font-weight: 400;
      margin-top: 5px;
      text-transform: initial;
      font-style: italic;
      color: $gray-light;
      font-size: .80rem;
      letter-spacing: .01rem;
      line-height: 1rem;
      // color: rgb(173, 178, 191);
    }
    i {
      cursor: pointer;
    }
  }
}