/* ------------------------------------------------------------ *\
	navbar
\* ------------------------------------------------------------ */

.navbar {
	border-top: 3px solid $app-yellow;

	&-left {
		float: left;

		// for buttonspacing
		& .navbar-nav {
			& .btn {
				margin-right: 8px;
			}
		}
	}
	&-right {
		float: right;
		
		// for buttonspacing
		& .navbar-nav {
			& .btn {
				margin-left: 8px;
			}
		}
    }

	&-engage {
		float: right;
	}

	&-nav {
		flex-direction: row;

		.dropdown .dropdown-menu {
            margin-top: 0;
            right: 0;
		}

		.dropdown-item i {
			margin-right: 2px;
			font-size: 1rem;
		}
	}

	&-title {
		text-align: center;
		.dashboard-marker-links a:hover {
			color: $app-yellow;
		}
		@media (max-width: 767px) {
			display: none;
		}
		& > h4 {
			color: white;
			margin: 0 265px 0 210px;
			font-weight: bold;
			line-height: 36px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.hello-user {
		width: 240px;
		border-left: 1px solid #434857;

		& > button {
			display: flex;
			justify-content: space-between;
			background: transparent;
			border: 0;
			color: #fff;
			width: 100%;
			cursor: pointer;
			text-align: left;
			padding: .1rem 0.2rem;
			font-size: 1rem;
			align-items: center;

			& > * {
				margin: 0;
			}

			& div {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			& p {
				display: inline;
				margin: 0px 10px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			
			& i {
				margin-left: 5px;
				font-size: 2rem;
				color: $gray;
				
				font-weight: 200;
			}
			
			&:hover > i {
				color: #e6ebef;
				font-weight: 300;
			}
		}
	}

	.hello-user > button img {
		margin-right: 10px;
	}
	.hello-user > button:after {
		margin-left: auto;
	}
	

	@media (max-width: 767px) {
		&-title {
			clear: both;
			padding-top: 10px;
		}
		&-title h4 {
			font-size: 16px;
		}
	}
}
