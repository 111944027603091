/* ------------------------------------------------------------ *\
	avatar
\* ------------------------------------------------------------ */

.avatar {
  position: relative;
  color: #fff;
  display: inline-block;
  margin: 0 auto;
  border-radius: 50%;
  text-align: center;
  font-weight: 300;
  font-style: initial;
  img {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    z-index: 1;
  }
}
