/* ------------------------------------------------------------ *\
	Page
\* ------------------------------------------------------------ */

.page {
	&-access { min-height: 100vh; padding: 70px 0; }
	&-account { min-height: 100vh; padding: 54px 0; background: $brand-white;
		& .navbar .row.justify-content-between .col-4:nth-child(2) {
			display: flex;
			justify-content: flex-end;
        }
        
        & .btn-back {
            font-style: italic;
        }
	}
	&-bland {
    min-height: 100vh; padding: 50px 0; background: $brand-white;
    border-top: 3px solid $app-yellow;
		&-legal {
			.legal-links {
				&-header {
					margin-top: 1rem;
					font-weight: 400;
					// makes it so the first header element does not have a top margin
					&:first-child {
						margin-top: 0;
					}
				}
				&-option {
					display:flex;
					align-items: center;
					justify-content: flex-start;
					margin: 10px 0;
					border-left: 2px solid transparent;
					& h5 {
						font-weight: inherit;
						color: black;
						padding: 0 5px;
						font-size: 1.1rem;
					}
				}
				.option-selected {
					border-color: $app-yellow;
					font-weight: 400;
				}
			}
		}
		&-pricing {
			.access-logo {
				position: absolute;
				top: 40px;
				left: 35px;
			}
			@media (max-width: 1100px) {
				.access-logo {
					display: none;
				}
			}
		}
	}

	@media (max-width: 767px) {
		&-access {padding: 15px 0;}
	}
}