/* ------------------------------------------------------------ *\
	commentblock
\* ------------------------------------------------------------ */

.commentblock {
	border-radius: 0;
	border-width: 2px 0 0;
	position: relative;

	.card-block {
		padding: 1rem 1rem 2px 0.75rem;
		transition: all 0.2s ease-in-out;

		.dropdown-menu .dropdown-item {
			display: flex;
			justify-content: space-between;
			i {
				align-self: center;
			}
		}

		.icon-delete {
			right: 10px;
			position: absolute;
			bottom: 11px;
			color: $text-muted;
			display: none;
		}
	}

	&:first-child {
	} /* DO NOT DELETE - FOR IN-APP CUES */

	&:last-child {
		margin-bottom: 100px;
	}

	&:hover .card-block {
		background-color: lighten(#2e323c, 2%);
	}

	&:hover .card-block .icon-delete {
		display: block;
	}

	&-head {
		padding: 0 50px 10px 40px;
	}
	&-head span {
		float: left;
		margin-left: -40px;
	}
	&-head img {
		float: left;
		margin-left: -40px;
	}

	&-title h5 {
		line-height: 1;
		font-size: 0.8rem;
		color: $text-muted;
		font-weight: 600;
		padding: 2px 2px;
		margin-top: 4px;
		max-width: inherit;
		overflow: hidden;
		white-space: nowrap;
        text-overflow: ellipsis;
        
        & span {
            font-weight: 400;
            display: contents;
        }
	}
	&-title h6 {
		line-height: 15px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-weight: 400;
		// used to make editing the title look effortless
		padding: 1px 0 2px;
	}
	&-title input {
		font-size: 1rem;
		padding: 3px;
		width: 90%;
		display: none;
	}
	&-title-editing input {
		display: block;
	}
	&-title-editing h6 {
		display: none;
	}

	&-time {
		position: absolute;
		right: 12px;
		top: 12px;
		font-size: 1rem;
		font-weight: 400;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
	}
	&-time .icon-play {
		font-size: 1rem;
		margin-right: 10px;
	}

	&-time-primary:active,
	&-time-success:active,
	&-time-danger:active,
	&-time-info:active,
	&-time-warning:active,
	&-time-secondary:active,
	&-time-default:active,
	&-time-plum:active,
	&-time-tangerine:active {
		font-weight: 600;
	}

	&-time-primary:hover {
		color: $brand-primary;
	}

	&-time-success:hover {
		color: $brand-success;
	}

	&-time-danger:hover {
		color: $brand-danger;
	}

	&-time-info:hover {
		color: $brand-info;
	}

	&-time-warning:hover {
		color: $brand-warning;
	}

	&-time-secondary:hover {
		color: lighten($gray-light, 20%);
	}

	&-time-default:hover {
		color: lighten($gray-light, 20%);
	}

	&-time-plum:hover {
		color: $brand-plum;
	}

	&-time-tangerine:hover {
		color: $brand-tangerine;
	}

	&-places {
		margin-bottom: 7px;
		user-select: none;
	}
	&-places ul {
		list-style: none outside none;
		margin: 0;
        padding: 0;
        margin-right: 1rem;
	}
	&-places li {
		border-left: 1px solid #fff;
		padding: 0 5px;
		font-size: 11px;
		line-height: 14px;
		display: inline-block;
		font-weight: 600;
        text-transform: uppercase;
        overflow: hidden;
	}
	&-places .unselected {
		font-weight: 400;
        max-width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: $gray !important;
        font-style: italic;
        i {
            padding-right: 3px;
        }
	}


	&-places li:first-child {
		border-left: 0;
		padding-left: 0;
	}
	&-places-select {
		display: none;
	}
	&-places-select-editing select {
		display: block;
	}

	&-icons-right {
		position: absolute;
		right: 12px;
		top: 45px;
		text-align: center;
	}
	&-icons-right .icon-holder {
		display: block;
		height: 20px;
		z-index: 10;
	}
	&-icons-right .icon-holder-hidden {
		display: none;
	}

	&:hover .icon-holder-hidden {
		display: block;
	}

	.dropdown-menu {
		margin-top: 0;
		top: 0;
	}
	.dropdown .dropdown-toggle::after {
		display: none;
	}

	select.form-control:not([size]):not([multiple]) {
		padding: 0 5px;
		height: 24px;
		line-height: 24px;
	}

	input.form-control:focus,
	textarea.form-control:focus,
	select.form-control:focus {
		background-color: #434857;
		border-color: #434857;
		color: #fff;
	}

	&-status-icon {
		float: right;
		color: #2e323c;
		font-size: 1rem;
		margin-top: 3px;
	}

	&-comment {
		position: relative;
	}
	&-comment p {
		padding: 3px;
		margin: 0;
		line-height: 18px;
	}
	&-comment textarea {
		display: none;
		padding: 3px 20px 3px 3px;
		height: 60px;
		margin-bottom: 10px;
		width: 90%;
		&::placeholder {
			color: $gray;
			font-style: italic;
		}
	}
	&-comment-editing textarea {
		display: block;
	}
	&-comment-editing p {
		display: none;
	}

	&-addreply {
		box-sizing: border-box;
		width: 100%;
		margin-bottom: 10px;
		margin-top: 10px;
	}

	&-addreply span {
		font-size: 0.8rem;
		font-weight: 400;
		display: block;
		color: $text-muted;
		// line-height: 23px;
		padding-left: 3px;
	}

	&-addreply textarea {
		padding: 3px;
		display: none;
		&::placeholder {
			color: $gray;
			font-style: italic;
		}
	}

	&-addreply-editing span {
		display: none;
	}
	&-addreply-editing textarea {
		display: block;
	}

	&-replies {
		padding: 5px 0;
		margin-right: -32px;
	}

	&.card-outline-primary &-icon,
	&.card-outline-primary &-places li {
		color: $brand-primary;
	}

	&.card-outline-secondary &-icon,
	&.card-outline-secondary &-places li {
		color: $gray-light;
	}

	&.card-outline-success &-icon,
	&.card-outline-success &-places li {
		color: $brand-success;
	}

	&.card-outline-danger &-icon,
	&.card-outline-danger &-places li {
		color: $brand-danger;
	}

	&.card-outline-default &-icon,
	&.card-outline-default &-places li {
		color: $gray-light;
	}

	&.card-outline-plum &-icon,
	&.card-outline-plum &-places li {
		color: $brand-plum;
	}

	&.card-outline-tangerine &-icon,
	&.card-outline-tangerine &-places li {
		color: $brand-tangerine;
	}

	&.card-outline-warning &-icon,
	&.card-outline-warning &-places li {
		color: $brand-warning;
	}

	&.card-outline-info &-icon,
	&.card-outline-info &-places li {
		color: $brand-info;
	}
}

.commentblocks-scroll-wrap {
	position: absolute;
	top: 60px;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	margin-top: 3px;
	// commentblock-fade
	.commentblock-fade {
		&-enter,
		&-appear {
			opacity: 0.01;
		}
		&-enter.commentblock-fade-enter-active,
		&-appear.commentblock-fade-appear-active {
			opacity: 1;
			@include __transitions(500ms);
		}
		&-exit {
			opacity: 1;
			right: 0px;
		}
		&-exit.commentblock-fade-exit-active {
			opacity: 0.01;
			right: -100px;
			@include __transitions(300ms);
		}
	}
}

@media (max-width: 767px) {
	.commentblocks-scroll-wrap {
		position: static;
	}
}
