/* ------------------------------------------------------------ *\
	analysis
\* ------------------------------------------------------------ */

.analysis {
    // keyboard shortcuts
    &-keyb-shortcuts {
        color: $gray;
        font-weight: 400;
        font-size: 0.8rem;
        font-style: italic;
    }

	&-dashboard {
        padding: 0;
        margin: 0;

        &-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 1rem;
    
            // &-buttons {
            // 	margin-bottom: 1rem;
            //     min-height: 90px;
            //     align-items: center;
            //     justify-content: center;
            //     margin: auto;
            // }
    
            min-width: 100%;
            a p {
                margin: 0;
                font-weight: 400;
                color: $gray-light;
                font-style: italic;
            }
            a:hover,
            p:hover {
                color: $gray;
            }
        }

		&-buttons {
            align-items: center;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 1rem;
            margin: auto;
            max-width: 710px;
            min-height: 90px;
		}

		/** Dashboard Event Buttons **/
		&-single-button {
			margin: 10px 5px 0px;
			width: 150px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 0.5rem 0.2rem;

			&-edit:focus {
				color: $gray-darker;
				background: $gray-lighter;
			}
		}
		@media (max-width: 845px) and (min-width: 768px) {
			&-single-button {
				width: calc(50% - 20px);
			}
		}
		@media (max-width: 550px) {
			&-single-button {
				width: calc(50% - 20px);
			}
        }
        
        &-comment {
            width: 100%;
            max-width: 1100px;
            margin: auto;
    
            &-inputgroup {
                margin-bottom: 5px;
    
                & textarea {
                    border: none;
                    height: 50px;
                    margin: 0px;
                }
    
                & textarea::placeholder {
                    color: $gray-light;
                    font-style: italic;
                }
    
                & button {
                    background-color: #434857;
                    border-width: 0px 0px 0px 1px;
                    border-color: rgba(0, 0, 0, 0.15);
                    &:hover {
                        color: #fff;
                        // cursor: pointer;
                        border-color: rgba(0, 0, 0, 0.15);
                    }
                    &:focus {
                        outline: 0;
                    }
                }
            }
        }
    
        &-nav {
            cursor: pointer;
            color: $gray-dark;
            opacity: 0.5;
            border: none;
            background: none;
            padding: 0;
            margin: auto;
            @include hover-focus {
                color: $gray-dark;
                outline: 0;
                opacity: 0.5;
            }
        }
        // &-dashboard-nav-prev { left: 0; float: left;}
        // &-dashboard-nav-next { right: 0; }
        &-nav:hover {
            color: $gray-light;
        }
        &-nav:active {
            color: $gray-dark;
        }
        // &-dashboard-content:hover &-dashbaord-nav { display: block; }
    }
}
#show-db-btn {
	text-align: center;
	width: fit-content;
	margin: 5px auto;
	&:hover {
		color: $gray-light;
		cursor: pointer;
	}
}

