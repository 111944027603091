/* ------------------------------------------------------------ *\
	collapsebox
\* ------------------------------------------------------------ */

.collapsebox {
	&-head {
		border-bottom: 1px solid #4d5262;
		padding: 10px;
		cursor: pointer;
		font-size: 17px;
		font-weight: 400;
		user-select: none;
	}
	&-body {
		display: none;
		border-bottom: 1px solid $text-muted;
	}
	&-count {
		margin: 0 1em;
		padding: 0 6px;
		background: #fff;

		border-radius: .25rem;
		line-height: 1px;
		font-size: 85%;
	}

	&-open &-head {
		border-bottom: 0;
	}
	&-open &-body {
		display: block;
	}

	&-primary &-count {
		color: $brand-primary;
	}
	&-success &-count {
		color: $brand-success;
	}
	&-danger &-count {
		color: $brand-danger;
	}
	&-warning &-count {
		color: $brand-warning;
	}

	&-primary &-head {
		border-bottom-color: $brand-primary;
	}
	&-success &-head {
		border-bottom-color: $brand-success;
	}
	&-danger &-head {
		border-bottom-color: $brand-danger;
	}
	&-warning &-head {
		border-bottom-color: $brand-warning;
	}
}
