@mixin flexing($direction) {
	display: flex;
	flex-direction: $direction;
}

.checkout-form {
    @include flexing(column);
    // margin-left: auto;
    // margin-right: auto;
	// margin-top: 8px;
    // max-width: 600px;

	select option {
		text-transform: capitalize;
	}

	.button-group {
		@include flexing(row);
		justify-content: space-between;
		margin-top: 1rem;
	}

	.plan-free {
		color: $gray-dark;
		align-self: center;
		font-weight: 400;
	}

	& .group {
		@include flexing(row);
		justify-content: space-between;
	}
    
    & .toggle-switch {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        font-size: 12.8px;
        .highlighted {
            color: $primary-color;
            font-weight: 400;
        }
        .notHighlighted {
            color: $gray-light;
            font-weight: 400;
        }

        .slider {
            margin: 0 5px;
        }
    }

	& .plan-details {
		margin-bottom: 0.3rem;
		@include flexing(column);
		&-pricing {
			@include flexing(row);
			justify-content: space-between;
			h5 {
				font-size: 1.1rem;
				font-weight: 400;
				color: $gray-dark;
                text-transform: capitalize;
                margin: initial;
                margin-top: .5rem;
			}
		}
		&-information {
			color: $gray-light;
			font-size: .9rem;
            margin-top: 0.3rem;
            font-style: italic;
		}
	}

	& .card-title {
		margin-bottom: 0;
        justify-content: center;
        h5 {
            // font-size: 1.3rem;
            // font-weight: 400;
            // color: $gray-dark;
        }
        
	}

	#address-fields {
		@include flexing(column);
		& .inherit-div {
			display: inherit;
			flex-direction: inherit;
		}
		&-street {
			@include flexing(column);
			& .inherit-div {
				display: inherit;
				flex-direction: inherit;
			}
		}
		&-country {
			& > * {
				width: 31%;
			}
			& .inherit-div {
				flex-direction: column;
			}
		}
		select {
			@include input-style(1.1rem, $gray-dark, lightgrey);
			height: 29px;
			&:invalid {
				color: red;
            }
            &:focus {
                border: $brand-teal;
            }
		}
		.inherit-div {
			margin-bottom: 1.3rem;
		}
	}

	span {
		color: $primary-color;
		font-weight: 400;
		font-size: 1rem;
	}

	// h5 {
	// 	margin: 0 !important;
	// }

	.slider {
		margin: 0 5px;
		@include flexing(row);
		align-self: center;
	}

	input {
        @include input-style(1.1rem, $gray-dark, lightgrey);
        line-height: inherit;
		&::placeholder {
			color: $gray-light;
        }
        &:focus {
            border-bottom-color: $brand-teal;
        }
	}

	.card-details {
		width: 100%;
		margin-bottom: 1rem;
		#h6-small {
			color: $primary-color;
		}
		&-address {
			margin-bottom: 1.3rem;
		}
	}

	.show-coupon {
		&-toggle {
			color: $brand-teal;
			margin: 0;
			font-style: italic;
			cursor: pointer;
		}
		&-div {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin: 0;
			input {
				width: auto;
				flex: 0;
			}
			button {
				font-size: 0.7rem;
				padding: 0.5rem;
			}
			.msg {
				margin: 0;
				flex: 0 0 100%;
			}
		}
	}

	.highlighted {
		color: $brand-primary;
		font-weight: 400;
	}
	.notHighlighted {
		color: $gray-light;
	}
	#checkout-error {
		text-align: center;
		margin-bottom: 1rem;
	}
}
