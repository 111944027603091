/* ------------------------------------------------------------ *\
	progressbar
\* ------------------------------------------------------------ */

.progressbar {
	display: flex;
	flex-direction: column;
	font-size: 0.8rem;
	min-width: 420px;

	&-title {
		// color: #aaa;
	}

	&-bar {
		background: $brand-primary;
		border-radius: 5px;
		height: 4px;
		margin: .3rem 0 .3rem;
	}

	&-stats {
		color: $brand-gray;
	}
}
